import React from "react";
import { Link } from "react-router-dom";

const ContactUs = () => {
  return (
    <div style={{ backgroundColor: "black" }}>
      <div className="section">
        <div className="container">
          <div className="columns is-centered" style={{ marginTop: 45 }}>
            <div className="column is-5">
              {/* <div className="box"> */}
              <p style={{ color: "white", marginBottom: 9 }}>Your Name</p>
              <input
                class="input"
                type="text"
                placeholder=""
                style={{
                  borderRadius: 0,
                  marginBottom: 18,
                  backgroundColor: "#1B191B",
                }}
              />

              <p style={{ color: "white", marginBottom: 9 }}>Email</p>
              <input
                class="input"
                type="email"
                placeholder=""
                style={{
                  borderRadius: 0,
                  marginBottom: 18,
                  backgroundColor: "#1B191B",
                }}
              />

              <p style={{ color: "white", marginBottom: 9 }}>Subject</p>
              <input
                class="input"
                type="email"
                placeholder=""
                style={{
                  borderRadius: 0,
                  marginBottom: 18,
                  backgroundColor: "#1B191B",
                }}
              />

              <p style={{ color: "white", marginBottom: 9 }}>Message</p>
              <textarea
                class="textarea "
                placeholder="Type your message here.."
                style={{
                  borderRadius: 0,
                  marginBottom: 18,
                  backgroundColor: "#1B191B",
                }}
              />

              <Link to="/">
                <center>
                  {" "}
                  <button
                    class="button"
                    style={{
                      fontWeight: 700,
                      borderRadius: 0,
                      backgroundColor: "#F7F700",
                      border: "none",
                      textAlign: "center",
                      paddingLeft: 201,
                      paddingRight: 204,
                    }}
                  >
                    SUBMIT
                  </button>
                </center>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default ContactUs;
