import React from "react";
import Images from "./Images";
import PricingCard from "./PricingCard";
import WorkCard from "./WorkCard";

const Projct = () => {
  return (
    <div style={{backgroundColor:"#010001"}}>
      <Images heading="PROJECTS." text="Some of my most recent works." />
      <WorkCard />
      <PricingCard />
    </div>
  );
};

export default Projct;
