import React from 'react'
import Images from './Images'
import PricingCard from './PricingCard'
import AboutContent from './AboutContent'

const About = () => {
  return (
    <div>
    <Images heading="ABOUT." text="I'm a React Front-End Developer."/><br/><br/>
   
    <AboutContent/>
      
    </div>
  )
}

export default About
