import React from "react";
import { Link } from "react-router-dom";
const PricingCard = () => {
  return (
    <div><br/><br/><br/>
      <div className="section">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-3">
              <div
                className="box"
                style={{ backgroundColor: "black", color: "white",border:"3px solid white",borderRadius:0 }}
              >
                <h6
                  className="is-size-6 has-text-centered"
                  style={{ fontWeight: 700 }}
                >
                  -Basic-
                </h6>
                <hr style={{ width: "30px", marginLeft: 100 }} />

                <h1
                  className="is-size-1 has-text-centered"
                  style={{ fontWeight: 700, marginBottom: 12 }}
                >
                  $ 100
                </h1>

                <p
                  style={{
                    textAlign: "center",
                    fontWeight: 600,
                    marginBottom: 6,
                  }}
                >
                  -3 Days-
                </p>
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: 600,
                    marginBottom: 6,
                  }}
                >
                  -3 Pages-
                </p>

                <p
                  style={{
                    textAlign: "center",
                    fontWeight: 600,
                    marginBottom: 6,
                  }}
                >
                  -Featured-
                </p>
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: 600,
                    marginBottom: 35,
                  }}
                >
                  -Responsive Design-
                </p>

                <center>
                  <Link to="/contact">
                    <button
                      className="button"
                      style={{
                        backgroundColor: "#F7F700",
                        borderRadius: 0,
                        fontWeight: 700,
                      }}
                    >
                      PURCHASE NOW
                    </button>
                  </Link>
                </center>
              </div>
            </div>

            <div className="column is-3">
              <div
                className="box"
                style={{ backgroundColor: "black", color: "white",border:"3px solid white",borderRadius:0 }}
              >
                <h6
                  className="is-size-6 has-text-centered"
                  style={{ fontWeight: 700 }}
                >
                  -Premium-
                </h6>
                <hr style={{ width: "30px", marginLeft: 100 }} />

                <h1
                  className="is-size-1 has-text-centered"
                  style={{ fontWeight: 700, marginBottom: 12 }}
                >
                  $ 200
                </h1>

                <p
                  style={{
                    textAlign: "center",
                    fontWeight: 600,
                    marginBottom: 6,
                  }}
                >
                  -2 Days-
                </p>
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: 600,
                    marginBottom: 6,
                  }}
                >
                  -5 Pages-
                </p>

                <p
                  style={{
                    textAlign: "center",
                    fontWeight: 600,
                    marginBottom: 6,
                  }}
                >
                  -Featured-
                </p>
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: 600,
                    marginBottom: 35,
                  }}
                >
                  -Responsive Design-
                </p>

                <center>
                  <Link to="/contact">
                    <button
                      className="button"
                      style={{
                        backgroundColor: "#F7F700",
                        borderRadius: 0,
                        fontWeight: 700,
                      }}
                    >
                      PURCHASE NOW
                    </button>
                  </Link>
                </center>
              </div>
            </div>

            <div className="column is-3">
              <div
                className="box"
                style={{ backgroundColor: "black", color: "white",border:"3px solid white",borderRadius:0 }}
              >
                <h6
                  className="is-size-6 has-text-centered"
                  style={{ fontWeight: 700 }}
                >
                  -Buisness-
                </h6>
                <hr style={{ width: "30px", marginLeft: 100 }} />

                <h1
                  className="is-size-1 has-text-centered"
                  style={{ fontWeight: 700, marginBottom: 12 }}
                >
                  $ 300
                </h1>

                <p
                  style={{
                    textAlign: "center",
                    fontWeight: 600,
                    marginBottom: 6,
                  }}
                >
                  -5 Days-
                </p>
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: 600,
                    marginBottom: 6,
                  }}
                >
                  -8 Pages-
                </p>

                <p
                  style={{
                    textAlign: "center",
                    fontWeight: 600,
                    marginBottom: 6,
                  }}
                >
                  -Featured-
                </p>
                <p
                  style={{
                    textAlign: "center",
                    fontWeight: 600,
                    marginBottom: 35,
                  }}
                >
                  -Responsive Design-
                </p>

                <center>
                  <Link to="/contact">
                    <button
                      className="button"
                      style={{
                        backgroundColor: "#F7F700",
                        borderRadius: 0,
                        fontWeight: 700,
                      }}
                    >
                      PURCHASE NOW
                    </button>
                  </Link>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingCard;
