import React from "react";

const Footer = () => {
  return (
    <div><br/><br/><br/><br/><br/><br/><br/><br/>
      <footer
        class="footer"
        style={{
          position: "fixed",
          width: "100%",
          bottom: 0,
          height: "200px",
          backgroundColor: "#0F0E0F",
          color: "white",
        }}
      >
        <div>
          <div className="section">
            <div className="container">
              <div className="columns">
                <div className="column is-6" style={{ marginTop: -45 }}>
                  <i class="fa-solid fa-house"></i>
                  <h6
                    className="is-size-6"
                    style={{ marginTop: -24, marginLeft: 35, marginBottom: 6 }}
                  >
                    123 Housing Society.
                    <br />
                    Bangladesh.
                  </h6>
                  <i class="fa fa-phone"></i>
                  <h6
                    className="is-size-6"
                    style={{ marginTop: -24, marginLeft: 35, marginBottom: 6 }}
                  >
                    1-2323-343-23
                  </h6>
                  <i class="fa fa-envelope"></i>
                  <h6
                    className="is-size-6"
                    style={{ marginTop: -24, marginLeft: 35, marginBottom: 20 }}
                  >
                    info@gmail.com
                  </h6>
                </div>

                <div className="column is-6">
                  <h5
                    className="is-size-5"
                    style={{ marginBottom: 6, marginTop: -60, fontWeight: 600 }}
                  >
                    About the company
                  </h5>
                  <h6 className="is-size-6" style={{ marginBottom: 10 }}>
                    This is me Vachan Patel.CEO & Founder of Tech2etc.
                    <br /> I enjoy discussing new projects and design
                    <br /> challenges.
                  </h6>

                  <i
                    class="fa-brands fa-facebook"
                    style={{ fontSize: 25, marginBottom: 20 }}
                  ></i>
                  <i
                    class="fa-brands fa-twitter"
                    style={{ fontSize: 25, marginLeft: 15, marginBottom: 20 }}
                  ></i>
                  <i
                    class="fa-brands fa-linkedin"
                    style={{ fontSize: 25, marginLeft: 15, marginBottom: 20 }}
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
