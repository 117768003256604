import React from "react";
import Images from "./Images";
import ContactUs from "./ContactUs";

const Contact = () => {
  return (
    <div>
      <Images heading="CONTACT." text="Let's have create a chat. " />
      <br />
      <ContactUs />
    </div>
  );
};

export default Contact;
