import React from "react";
import project1 from "../assets/project1.png";
import project2 from "../assets/project2.png";
import project3 from "../assets/project3.png";

import { NavLink } from "react-router-dom";

const WorkCard = (props) => {
  return (
    <div>
      <div className="section">
        <div className="container">
          <h2
            className="is-size-2"
            style={{
              textAlign: "center",
              marginTop: 35,
              fontWeight: 700,
              color: "white",
              marginBottom: 18,
            }}
          >
            Projects
          </h2>

          <div className="columns is-centered">
            <div className="column is-4">
              <div
                className="box is-centerd"
                style={{ backgroundColor: "#131314", height: 730 }}
              >
                <img
                  src={project1}
                  alt="Imagess"
                  style={{ width: "100%", height: "25vh", marginBottom: 9 }}
                />
                <h4
                  className="is-size-4"
                  style={{
                    textAlign: "center",
                    fontWeight: 800,
                    color: "white",
                    marginBottom: 9,
                  }}
                >
                  Ecommerce Website
                  <br /> Design
                </h4>
                <h5
                  className="is-size-5"
                  style={{
                    textAlign: "center",
                    color: "white",
                    marginBottom: 12,
                  }}
                >
                  "An e-commerce website is one that allows people to buy and
                  sell physical goods, services, and digital products over the
                  internet rather than at a brick-and-mortar location. Through
                  an e-commerce website, a business can process orders, accept
                  payments, manage shipping and logistics, and provide customer
                  service."
                </h5>

                <NavLink to="https://www.youtube.com/watch?v=P8YuWEkTeuE&pp=ygU6YnVpbGQgYW5kIGRpc3BsYXkgZWNjb21tbWVyY2Ugd2Vic2l0ZSB1aW5nIHJlYWN0IHRlY2gyIGV0Yw%3D%3D">
                  <button
                    className="button"
                    style={{
                      backgroundColor: "#F7F700",
                      fontWeight: 700,
                      marginRight: 155,
                      borderRadius: 0,
                    }}
                  >
                    VIEW
                  </button>
                </NavLink>
                <NavLink to="url.com">
                  <button
                    className="button"
                    style={{
                      backgroundColor: "#F7F700",
                      fontWeight: 700,
                      borderRadius: 0,
                    }}
                  >
                    SOURCE
                  </button>
                </NavLink>
              </div>
            </div>

            <div className="column is-4">
              <div
                className="box is-centerd"
                style={{ backgroundColor: "#131314", height: 730 }}
              >
                <img
                  src={project2}
                  alt="Imagess"
                  style={{ width: "100%", height: "25vh", marginBottom: 9 }}
                />
                <h4
                  className="is-size-4"
                  style={{
                    textAlign: "center",
                    fontWeight: 800,
                    color: "white",
                    marginBottom: 9,
                  }}
                >
                  LMS/Course Webiste
                  <br /> Design
                </h4>
                <h5
                  className="is-size-5"
                  style={{
                    textAlign: "center",
                    color: "white",
                    marginBottom: 30,
                  }}
                >
                  Web designers plan, create and code internet sites and web
                  pages, many of which combine text with sounds, pictures,
                  graphics and video clips. A web designer is responsible for
                  creating the design and layout of a website or web pages.
                </h5>
                <br />
                <br />
                <br />

                <NavLink to="https://www.youtube.com/watch?v=RamIl-pjGE4&pp=ygU2TE1TL0NvdXJzZSBXZWJpc3RlIERlc2lnbiB3ZWJzaXRlIHVpbmcgcmVhY3QgdGVjaDIgZXRj">
                  <button
                    className="button"
                    style={{
                      backgroundColor: "#F7F700",
                      fontWeight: 700,
                      marginRight: 155,
                      borderRadius: 0,
                    }}
                  >
                    VIEW
                  </button>
                </NavLink>
                <NavLink to="url.com">
                  <button
                    className="button"
                    style={{
                      backgroundColor: "#F7F700",
                      fontWeight: 700,
                      borderRadius: 0,
                    }}
                  >
                    SOURCE
                  </button>
                </NavLink>
              </div>
            </div>

            <div className="column is-4">
              <div
                className="box is-centerd"
                style={{ backgroundColor: "#131314", height: 730 }}
              >
                <img
                  src={project3}
                  alt="Imagess"
                  style={{ width: "100%", height: "25vh", marginBottom: 9 }}
                />
                <h4
                  className="is-size-4"
                  style={{
                    textAlign: "center",
                    fontWeight: 800,
                    color: "white",
                    marginBottom: 9,
                  }}
                >
                  Rymo Ecommerce <br />
                  Design
                </h4>
                <h5
                  className="is-size-5"
                  style={{
                    textAlign: "center",
                    color: "white",
                    marginBottom: 40,
                  }}
                >
                  Rymo Creative Services can design, maintain and recommend
                  hosting and web solutions for all of your Internet presence
                  needs. We have over ten years of experience with web design
                  utilizing various web authoring tools including Dreamweaver
                  CS3, WordPress, HTML Kit, and Notepad.
                </h5>

                <NavLink to="https://www.youtube.com/watch?v=37KohMnlP7Q&pp=ygUyUnltbyBFY29tbWVyY2UgRGVzaWduIHdlYnNpdGUgdWluZyByZWFjdCB0ZWNoMiBldGM%3D">
                  <button
                    className="button"
                    style={{
                      backgroundColor: "#F7F700",
                      fontWeight: 700,
                      marginRight: 155,
                      borderRadius: 0,
                    }}
                  >
                    VIEW
                  </button>
                </NavLink>
                <NavLink to="url.com">
                  <button
                    className="button"
                    style={{
                      backgroundColor: "#F7F700",
                      fontWeight: 700,
                      borderRadius: 0,
                    }}
                  >
                    SOURCE
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkCard;
