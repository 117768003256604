import React from "react";
import { Link } from "react-router-dom";


const Navbar = () => {
  return (
    <>
      <div className="columns" style={{ backgroundColor: "black" }}>
        <div className="column is-7" style={{ marginTop: 12 }}>
          <h3
            className="is-size-3"
            style={{ marginLeft: 45, color: "white", fontWeight: 700 }}
          >
            Portfolio
          </h3>
        </div>

        <div className="column is-1" style={{ marginTop: 20, fontSize: 19 }}>
          <Link to="/" style={{ color: "white", fontWeight: 600 }}>
            Home
          </Link>
        </div>

        <div className="column is-1" style={{ marginTop: 20, fontSize: 19 }}>
          <Link to="/project" style={{ color: "white", fontWeight: 600 }}>
            Project
          </Link>
        </div>

        <div className="column is-1" style={{ marginTop: 20, fontSize: 19 }}>
          <Link to="/about" style={{ color: "white", fontWeight: 600 }}>
            About
          </Link>
        </div>

        <div className="column is-1" style={{ marginTop: 20, fontSize: 19 }}>
          <Link to="/contact" style={{ color: "white", fontWeight: 600 }}>
            Contact
          </Link>
        </div>
      </div>

      {/* <div>
        <img src={introImg} alt="IntroImage" style={{ marginTop: -12 }} />
        <h4 className="is-size-4">HI,I'M A ENGINEER.</h4>
        <h2 className="is-size-2" style={{ color: "black" }}>
          REACT
          <br /> DEVELOPER
        </h2>

        <Link to="/project">
          <button
            class="button is-warning"
            style={{ marginRight: 12, fontWeight: 700 }}
          >
            PROJECTS
          </button>
        </Link>
        <Link to="/contact">
          <button class="button is-warning" style={{ fontWeight: 700 }}>
            CONTACT
          </button>
        </Link>
      </div> */}
    </>
  );
};

export default Navbar;
