import React, { Component } from "react";
import Image from "../assets/image2.avif";

class Images extends Component {
  render() {
    return (
      <div>
        <img
          src={Image}
          alt="Image2"
          style={{
            marginTop: -12,
            width: "100%",
            height: "40vh",
            opacity: 1.9,
          }}
        />
        <br />
        <br />
        <br />

        <div className="heading">
          <h3
            className="heading  is-size-3"
            style={{
              fontSize: 45,
              marginTop: -320,
              textAlign: "center",
              fontWeight: 800,
              color:"white",
            }}
          >
            {this.props.heading}
          </h3>
          <h5
            className="is-size-5"
            style={{ textAlign: "center", fontWeight: 600, color: "white" }}
          >
            {this.props.text}
          </h5>
        </div>
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}

export default Images;
