import React from "react";
import { Link } from "react-router-dom";
import react1 from "../assets/react1.jpg";
import react2 from "../assets/react2.webp";

const AboutContent = () => {
  return (
    <div style={{ backgroundColor: "black", color: "white" }}>
      <div className="section">
        <div className="container">
          <div class="columns">
            <div class="column is-6">
              <h3
                className="is-size-3"
                style={{
                  textAlign: "center",
                  fontWeight: 800,
                  marginBottom: 12,
                }}
              >
                Who Am I?
              </h3>

              <h5 className="is-size-5" style={{ textAlign: "center" }}>
                I am a react front-end developer.
                <br />
                also i creaet a responsive website for
                <br /> cilents
              </h5>
              <br />

              <Link to="/contact">
                <center>
                  {" "}
                  <button
                    class="button"
                    style={{
                      fontWeight: 700,
                      borderRadius: 0,
                      backgroundColor: "#F7F700",
                      border: "none",
                      textAlign: "center",
                    }}
                  >
                    CONTACT
                  </button>
                </center>
              </Link>
            </div>
            <div class="column is-6">
              {/* <img src={react1} alt="AbountContent1" style={{width:360}}/> */}
              <img
                src={react2}
                alt="AbountContent2"
                style={{ width: 400, marginTop: 20, marginLeft: 145 }}
              />
              <img
                src={react1}
                alt="AbountContent1"
                style={{ width: 360, marginTop: -200 }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutContent;
