import React from "react";
import { Link } from "react-router-dom";
import introImg from "../assets/intro-bg.jpg";

const Home = () => {

  return (
    <div>
      <img
        src={introImg}
        alt="IntroImage"
        style={{ marginTop: -12, opacity: 0.5,width:"100%" }}
      />
      <h4
        className="is-size-4"
        style={{
          marginTop: -650,
          marginLeft: 320,
          fontWeight: 600,
          color: "#000000",
          marginBottom: 12,
        }}
      >
        HI,I'M A ENGINEER.
      </h4>
      <h3
        className="is-size-3"
        style={{
          color: "black",
          marginLeft: 300,
          fontWeight: 700,
          marginBottom: 12,
        }}
      >
        REACT DEVELOPER
      </h3>

      <div style={{ marginLeft: 20 }}>
        <Link to="/project">
          <button
            class="button"
            style={{
              marginRight: 9,
              fontWeight: 700,
              marginLeft: 300,
              borderRadius: 0,
              backgroundColor: "#F7F700",
              border: "none",
            }}
          >
            PROJECTS
          </button>
        </Link>
        <Link to="/contact">
          <button
            class="button"
            style={{
              fontWeight: 700,
              borderRadius: 0,
              backgroundColor: "#F7F700",
              border: "none",
            }}
          >
            CONTACT
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Home;
